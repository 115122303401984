<template>
    <header id="main-header" class="main-header" :class="{
        'scrolled-v': setScrolledClass,
        'menu-toggled': toggleMenuVisual,
        'no-page-hero-bg': getPageHeroState,
    }">
        <div class="container">
            <span class="main-logo">
                <router-link @click="hidePopups()" to="/">
                    <img alt="LEAD Hockey - logo" src="@/assets/svgs/logo.svg" class="logo" />
                </router-link>
            </span>
            <button class="mobile-toggle-btn toggle-btn" :class="{ 'open': !showMobileMenu }" @click="toggleMobileMenu">

            </button>
            <nav class="main-nav" id="main-nav">
                <!-- Not logged in menu -->
                <ul v-if="!isAuthenticated && bigMenuObj">
                    <li v-for="  firstLevel in bigMenuObj  " :key="firstLevel.name" :class="{
        'has-sub-menu': firstLevel.children &&
            firstLevel.children.length > 0
    }
        ">

                        <router-link @click="hidePopups()" :to="firstLevel.url"
                            v-if="firstLevel.url && firstLevel.url !== '' && firstLevel.url !== '#'">{{
        firstLevel.name
    }}</router-link>
                        <button class="nav-item-looks" @click="hidePopups()" :to="firstLevel.url" v-else>{{
        firstLevel.name
    }}</button>
                        <ul v-if="firstLevel.children &&
        firstLevel.children.length > 0
        " class="sub-menu" :class="firstLevel.itemClass">
                            <li v-for="  secondLevel   in   firstLevel.children  " :key="secondLevel.name">
                                <router-link @click="hidePopups()" :to="secondLevel.url">{{
        secondLevel.name
    }}</router-link>
                            </li>
                        </ul>
                    </li>
                </ul>
                <!-- Logged in menu -->
                <ul v-if="isAuthenticated && bigMenuObjLoggedIn">
                    <li v-for="  firstLevel   in   bigMenuObjLoggedIn  " :key="firstLevel.name" :class="{
        'has-sub-menu': firstLevel.children &&
            firstLevel.children.length > 0
    }
        ">
                        <router-link @click="hidePopups()" :to="firstLevel.url"
                            v-if="firstLevel.url && firstLevel.url !== '' && firstLevel.url !== '#'">{{
        firstLevel.name
    }}</router-link>
                        <button class="nav-item-looks" @click="hidePopups()" :to="firstLevel.url" v-else>{{
        firstLevel.name
    }}</button>
                        <ul v-if="firstLevel.children &&
        firstLevel.children.length > 0
        " class="sub-menu" :class="firstLevel.itemClass">
                            <li v-for="  secondLevel   in   firstLevel.children  " :key="secondLevel.name">
                                <router-link @click="hidePopups()" :to="secondLevel.url">{{
        secondLevel.name
    }}</router-link>
                            </li>
                        </ul>
                    </li>
                </ul>

            </nav>

            <nav class="side-nav" id="side-nav">
                <ul>
                    <li class="login-btn" v-if="!isAuthenticated">
                        <button class="regular-btn" @click="loginPopup">
                            Login
                        </button>
                    </li>
                    <li class="subscription-btn" v-if="!isAuthenticated">
                        <router-link class="button" to="/contact">
                            Book demo
                        </router-link>
                    </li>
                    <li class="search-item" v-if="isAuthenticated">
                        <SearchBar />
                    </li>
                    <li class="has-sub-menu" v-if="isAuthenticated">
                        <button class="regular-btn has-children">
                            My profile
                        </button>
                        <ul class="sub-menu">
                            <li v-if="this.$store.state.getUserData &&
        this.$store.state.getUserData.role ===
        'Admin'
        ">
                                <router-link to="/dashboard">Dashboard</router-link>
                            </li>
                            <li v-else>
                                <router-link to="/account">Settings</router-link>
                            </li>
                            <li v-if="isAuthenticated">
                                <router-link to="/my-favourites">My favourites</router-link>
                            </li>
                            <li>
                                <router-link to="/my-journeys">My journeys</router-link>
                            </li>
                            <li>
                                <button @click="handleLogout()">Logout</button>
                            </li>
                        </ul>
                    </li>
                </ul>
            </nav>
        </div>
    </header>
    <transition name="fade">
        <div class="mobile-nav-wrapper" v-if="showMobileMenu">
            <div class="inner-nav-wrapper">
                <nav class="mobile-nav" id="mobile-nav">
                    <!-- Not logged in menu -->
                    <ul v-if="!isAuthenticated && bigMobileMenuObj">
                        <li v-for="  firstLevel   in   bigMobileMenuObj  " :key="firstLevel.name" :class="{
        'has-sub-menu': firstLevel.children &&
            firstLevel.children.length > 0
    }
        ">
                            <router-link @click="hidePopups()" :to="firstLevel.url"
                                v-if="firstLevel.url && firstLevel.url !== '' && firstLevel.url !== '#'">{{
        firstLevel.name
    }}</router-link>
                            <button class="nav-item-looks" @click="hidePopups()" :to="firstLevel.url" v-else>{{
        firstLevel.name
    }}</button>
                            <ul v-if="firstLevel.children &&
        firstLevel.children.length > 0
        " class="sub-menu" :class="firstLevel.itemClass">
                                <li v-for="  secondLevel   in   firstLevel.children  " :key="secondLevel.name">
                                    <router-link @click="hidePopups()" :to="secondLevel.url">{{
        secondLevel.name
    }}</router-link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <!-- Logged in menu -->
                    <ul v-if="isAuthenticated && bigMobileMenuObjLoggedIn">
                        <li v-for="  firstLevel in bigMobileMenuObjLoggedIn" :key="firstLevel.name" :class="{
        'has-sub-menu': firstLevel.children &&
            firstLevel.children.length > 0
    }
        ">
                            <router-link @click="hidePopups()" :to="firstLevel.url"
                                v-if="firstLevel.url && firstLevel.url !== '' && firstLevel.url !== '#'">{{
        firstLevel.name
    }}</router-link>
                            <button class="nav-item-looks" @click="hidePopups()" :to="firstLevel.url" v-else>{{
        firstLevel.name
    }}</button>
                            <ul v-if="firstLevel.children &&
        firstLevel.children.length > 0
        " class="sub-menu" :class="firstLevel.itemClass">
                                <li v-for="  secondLevel   in   firstLevel.children  " :key="secondLevel.name">
                                    <router-link @click="hidePopups()" :to="secondLevel.url">{{
                                        secondLevel.name
                                        }}</router-link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
                <nav class="second-navigation">
                    <ul>
                        <li class="search-bar-wrapper fullwidth" v-if="isAuthenticated">
                            <SearchBar activeSearchState="true" />
                        </li>
                        <li class="login-btn" v-if="!isAuthenticated">
                            <button class="button alt" @click="loginPopup">
                                Login
                            </button>
                        </li>
                        <li class="subscription-btn" v-if="!isAuthenticated">
                            <router-link data-txt="Start your journey" class="button" to="/sign-up">
                                Sign up
                            </router-link>
                        </li>
                        <li class="fullwidth" v-if="isAuthenticated &&
        this.$store.state.getUserData &&
        this.$store.state.getUserData.role ===
        'Admin'
        ">
                            <router-link class="button alt" to="/dashboard">Dashboard</router-link>
                        </li>
                        <li v-else-if="isAuthenticated">
                            <router-link class="button alt" to="/account">Settings</router-link>
                        </li>

                        <li v-if="isAuthenticated">
                            <button class="button alt" @click="handleLogout()">
                                Logout
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </transition>

    <transition name="fade">
        <LoginPopup v-if="$store.state.displayLoginPopup" @forgotPasswordClicked="forgotPasswordPopup" />
    </transition>
    <transition name="fade">
        <ForgotPasswordPopup @showLoginPopupAgain="loginPopup" v-if="$store.state.displayPasswordPopup" />
    </transition>
</template>

<script>
import { mapState } from "vuex";
import { getUserToken, getUserInfo, clearData } from "@streampac.io/chef_sp_1";

import { defineAsyncComponent } from "vue";

//@import component
import SearchBar from "@/components/SearchBar";

const LoginPopup = defineAsyncComponent(() =>
    import("@/components/LoginPopup")
);
const ForgotPasswordPopup = defineAsyncComponent(() =>
    import("@/components/ForgotPasswordPopup")
);

export default {
    data() {
        return {
            userData: null,
            showMobileMenu: false,
            showDesktopMenu: false,
            toggleMenuVisual: false,
            getPageHeroState: false,
            removeExtraMenu: false,
            removeLoginBar: false,
            scrollPositionInt: 0,
            setScrolledClass: false,
            bigMenuObj: [
                {
                    name: "Categories",
                    url: "#",
                    itemClass: "categories",
                    children: [
                        {
                            name: "Technical skills",
                            url: "/technical-skills",
                        },
                        {
                            name: "Training exercises",
                            url: "/training-exercises",
                        },
                        {
                            name: "Tactical classess",
                            url: "/tactical-classes",
                        },
                        {
                            name: "Masterclasses",
                            url: "/masterclasses",
                        },
                    ],
                },
                {
                    name: "Journeys",
                    url: "/journeys",
                    itemClass: "journeys",
                },
                {
                    name: "Memberships",
                    url: "/memberships/",
                    itemClass: "Memberships",
                },
                {
                    name: "About lead",
                    url: "#",
                    itemClass: "about",
                    children: [
                        {
                            name: "Who we are",
                            url: "/who-we-are",
                        },
                        {
                            name: "Trainers & coaches",
                            url: "/coaches-trainers",
                        },
                        {
                            name: "Contact",
                            url: "/contact",
                        },
                    ],
                },
            ],
            bigMenuObjLoggedIn: [
                {
                    name: "Categories",
                    url: "#",
                    itemClass: "categories",
                    children: [
                        {
                            name: "Technical skills",
                            url: "/categories/technical-skills",
                        },
                        {
                            name: "Training exercises",
                            url: "/categories/training-exercises",
                        },
                        {
                            name: "Tactical classess",
                            url: "/categories/tactical-classes",
                        },
                        {
                            name: "Masterclassses",
                            url: "/categories/masterclasses",
                        },

                    ],
                },
                {
                    name: "Journeys",
                    url: "/journeys",
                    itemClass: "journeys",
                },

                {
                    name: "Explore",
                    url: "#",
                    itemClass: "about",
                    children: [
                        {
                            name: "Coaches & trainers",
                            url: "/coaches-trainers",
                        },
                        {
                            name: "All videos",
                            url: "/all-videos",
                        },
                        {
                            name: "My favourites",
                            url: "/my-favourites",
                        },
                        {
                            name: "My journeys",
                            url: "/my-journeys",
                        },
                        {
                            name: "Blog",
                            url: "/blogs",
                        },
                    ],
                },
            ],
            bigMobileMenuObj: [
                {
                    name: "Technical skills",
                    url: "/technical-skills",
                },
                {
                    name: "Training exercises",
                    url: "/training-exercises",
                },
                {
                    name: "Tactical classess",
                    url: "/tactical-classes",
                },
                {
                    name: "Masterclasses",
                    url: "/masterclasses",
                },
                {
                    name: "Journeys",
                    url: "/journeys",
                    itemClass: "journeys",
                },
                {
                    name: "Memberships",
                    url: "/memberships/",
                    itemClass: "Memberships",
                },
                {
                    name: "Who we are",
                    url: "/who-we-are",
                },
                {
                    name: "Trainers & coaches",
                    url: "/coaches-trainers",
                },
                {
                    name: "Contact",
                    url: "/contact",
                },
            ],
            bigMobileMenuObjLoggedIn: [
                {
                    name: "Technical skills",
                    url: "/categories/technical-skills",
                },
                {
                    name: "Training exercises",
                    url: "/categories/training-exercises",
                },
                {
                    name: "Tactical classess",
                    url: "/categories/tactical-classes",
                },
                {
                    name: "Masterclassses",
                    url: "/categories/masterclasses",
                },
                {
                    name: "Journeys",
                    url: "/journeys",
                    itemClass: "journeys",
                },
                {
                    name: "Coaches & trainers",
                    url: "/coaches-trainers",
                },
                {
                    name: "My favourites",
                    url: "/my-favourites",
                },
                {
                    name: "My journeys",
                    url: "/my-journeys",
                },
                {
                    name: "Blog",
                    url: "/blogs",
                },
            ],

        };
    },
    inject: ["isAuthenticated", "setAuthenticated"],
    components: {
        LoginPopup,
        ForgotPasswordPopup,
        SearchBar,
        // Logo,
    },
    watch: {
        $route() {
            this.removeHeaderStuff();

            //Remove focus when chaning routes
            document.activeElement.blur();

            const bodyEl = document.body;
            bodyEl.classList.remove("remove-scroll");
        },
        storeIsAuthenticated(val) {
            this.removeHeaderStuff();

            const bodyEl = document.body;
            bodyEl.classList.remove("remove-scroll");

            if (val == true) {
                this.storeUserInfo();
            }
        },
    },
    created() {
        if (this.isAuthenticated) {
            this.storeUserInfo();
        }

        //Set initial scroll position
        setTimeout(() => {
            let st = window.scrollY;
            this.scrollPositionInt = st;

            this.getScrollPosition();
        }, 50);

        window.addEventListener("scroll", this.getScrollPosition);
    },
    updated: function () {
        if (this.isAuthenticated) {
            this.$store.commit("hideLoginPopup");
            this.$store.commit("hidePasswordPopup");
        }
    },
    computed: mapState(["storeIsAuthenticated"]),
    methods: {
        storeUserInfo() {
            setTimeout(() => {
                getUserToken((token) => {
                    this.setUserDetails(token);
                });
            }, 300);
        },
        handleLogout() {
            clearData();
            this.setAuthenticated(false);
            this.$store.commit("storeRemoveAuthenticatedStatus");
            this.$store.commit("deleteUserSavedData");

            if (this.$route.name == "Home") {
                setTimeout(() => {
                    window.location.reload();
                }, 200);
            } else {
                this.$router.push("/");
            }
        },
        loginPopup() {
            this.$store.commit("toggleLoginPopup");
        },
        forgotPasswordPopup() {
            this.$store.commit("hideLoginPopup");
            this.$store.commit("showPasswordPopup");
        },
        setUserDetails(token) {
            getUserInfo(token).then((response) => {
                this.userData = response;
                this.$store.commit("setUserData", response);
            });
        },
        hidePopups() {
            this.$store.commit("hideLoginPopup");
            this.$store.commit("hidePasswordPopup");

            //remove stuff when clicked
            this.showMobileMenu = false;
            this.toggleMenuVisual = false;

            this.showDesktopMenu = false;
        },
        toggleMobileMenu() {
            this.showMobileMenu = !this.showMobileMenu;
            this.toggleMenuVisual = !this.toggleMenuVisual;

            const bodyEl = document.body;
            bodyEl.classList.toggle("remove-scroll");

            //console.log(bodyEl);
        },
        toggleDesktopMenu() {
            this.showDesktopMenu = !this.showDesktopMenu;
            this.toggleMenuVisual = !this.toggleMenuVisual;
        },
        getScrollPosition() {
            let st = window.scrollY;

            if (this.scrollPositionInt > 80) {
                this.setScrolledClass = true;
            } else {
                this.setScrolledClass = false;
            }

            this.scrollPositionInt = st;
        },
        // setActiveSubMenu(val) {
        // 	this.activeSubMenuItem = val;

        // 	const objAsArray = Object.values(this.mainSubMenuObj);

        // 	objAsArray.filter((type) => {
        // 		if (type.typeName === val) {
        // 			this.activeSubMenuArray = type.children;
        // 		}
        // 	});
        // },
        showInnerSubMenu(val) {
            const btnEl = document.getElementsByClassName(val.toLowerCase());

            //console.log(btnEl  );
            btnEl[0].closest("li").classList.toggle("show-menu");
        },
        doesUserHasFreeSub() {
            const getUser = this.$store.state.getUserData;

            if (getUser) {
                if (getUser.subscription && getUser.subscription !== "Free") {
                    return false;
                }
            }

            return true;
        },
        removeHeaderStuff() {
            this.$store.commit("hideLoginPopup");
            this.$store.commit("hidePasswordPopup");
            this.showMobileMenu = false;
            this.showDesktopMenu = false;
            this.activeSubMenuItem = null;

            this.toggleMenuVisual = false;
            this.setScrolledClass = false;

            if (
                this.$route.name === "blogsOverview" ||
                this.$route.name === "RecipesOverview" ||
                this.$route.name === "RegisterPage" ||
                this.$route.name === "Pricing" ||
                this.$route.name === "HappySoulTravel" ||
                this.$route.name === "DelightYogaMembers" ||
                this.$route.name === "DelightCommunityMembers" ||
                this.$route.name === "ResetPasswordPage" ||
                this.$route.name === "Account" ||
                this.$route.name === "Search" ||
                this.$route.name === "BlogSingle" ||
                this.$route.name === "RecipeSingle"
            ) {
                this.getPageHeroState = true;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>
